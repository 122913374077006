import $ from 'jquery';

export default class GAReport {
  static init() {
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function() { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date();
      a = s.createElement(o), m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    ga('create', 'UA-85912907-1', 'auto');
    ga('send', 'pageview');

    $('[data-bs-target="#subscribe"]').click(function(){
      ga('send', {
        hitType: 'event',
        eventCategory: 'Sign Up',
        eventAction: 'open',
        eventLabel: 'get_in_seconds'//$(this).data('button')
      });
    });

    $('input.sign-up').click(function(){
      ga('send', {
        hitType: 'event',
        eventCategory: 'Sign Up',
        eventAction: 'signup',
        eventLabel: 'signup'
      });
    });

    $('.request-demo-button').click(function(){
      ga('send', {
        hitType: 'event',
        eventCategory: 'Sign Up',
        eventAction: 'demo_request'
      });
    });

    $('.learn-more-button').click(function(){
      ga('send', {
        hitType: 'event',
        eventCategory: 'Sign Up',
        eventAction: 'learn-more'
      });
    });

    var scrolledBelowMain = false;

    function checkScrollEvent(ev){
      if(window.pageYOffset > 650 && !scrolledBelowMain) {
        scrolledBelowMain = true
        ga('send', {
          hitType: 'event',
          eventCategory: 'Landing',
          eventAction: 'scroll',
          eventLabel: 'scrolled_below_first_window'
        });
      };
    }

    window.onscroll = checkScrollEvent;
  };
};
